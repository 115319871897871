import styled from 'styled-components';

import vars from 'variables';

const Root = styled.div`

  ${vars.desktop.mediaQuery} {
    /* styling */
  }

  ${vars.mobile.mediaQuery} {
    /* styling */
  }
`;

export { Root };
