import React, { useEffect, useState } from "react";
import { Root } from "./style";

import Layout from "components/Layout/Dashboard";
import FlipBox from "components/Common/FlipBox";
import SiteData from "components/Common/SiteData";
import Transactions from "components/Common/Transactions";

import moment from "moment";

import { formatNumber, inWindow } from "utils";

import { useDashboard } from "dashboard";
import { useWeb3 } from "web3";

const DashboardReflections = ({ location }) => {
  const { addresses, reflected, reflections } = useDashboard();
  const { bsc } = useWeb3();

  const [monthlyForecast, setMonthlyForecast] = useState(0);
  const [yearlyForecast, setYearlyForecast] = useState(0);

  useEffect(() => {
    const firstReflection = Object.entries(addresses.get())
      .reduce(
        (acc, [address, selected]) =>
          acc.concat(selected ? reflections[address] || [] : []),
        []
      )
      .filter((tx) => tx)
      .sort(
        (a, b) => moment(a.timestamp).unix() - moment(b.timestamp).unix()
      )[0];

    if (firstReflection) {
      const timestamp = moment(firstReflection.timestamp).unix();
      const now = Math.round(new Date().getTime() / 1000);
      const days = (now - timestamp) / (24 * 3600);
      const dayAverage = reflected / days;

      setMonthlyForecast(dayAverage * 30);
      setYearlyForecast(dayAverage * 365);
    } else {
      setMonthlyForecast(0);
      setYearlyForecast(0);
    }
  }, [addresses, reflected, reflections]);

  return (
    <Layout location={location}>
      {inWindow() ? (
        <>
          <Root data-row-count="3">
            <FlipBox>
              <div>
                <h4 className="hodl">Total $HODL Reflected</h4>
                <strong>{formatNumber(reflected, 0)} $HODL</strong>
                <small>${formatNumber(reflected * bsc.hodlUsdPrice, 2)}</small>
              </div>
              <div>
                <SiteData
                  className="info"
                  path="dashboard.reflectionsTotalReflections"
                />
              </div>
            </FlipBox>
            <FlipBox>
              <div>
                <h4 className="hodl">Monthly forecast</h4>
                <strong>{formatNumber(monthlyForecast, 0)} $HODL</strong>
                <small>
                  ${formatNumber(monthlyForecast * bsc.hodlUsdPrice, 2)}
                </small>
              </div>
              <div>
                <SiteData
                  className="info"
                  path="dashboard.reflectionsMonthlyForecast"
                />
              </div>
            </FlipBox>
            <FlipBox>
              <div>
                <h4 className="hodl">Yearly forecast</h4>
                <strong>{formatNumber(yearlyForecast, 0)} $HODL</strong>
                <small>
                  ${formatNumber(yearlyForecast * bsc.hodlUsdPrice, 2)}
                </small>
              </div>
              <div>
                <SiteData
                  className="info"
                  path="dashboard.reflectionsYearlyForecast"
                />
              </div>
            </FlipBox>
          </Root>
          <Transactions
            location={location}
            type="reflections"
            round={true}
            nohash={true}
          />
        </>
      ) : (
        ""
      )}
    </Layout>
  );
};

export default DashboardReflections;
