import React from "react";
import { useSiteData } from "utils";

import RawHTML from "components/Common/RawHTML";

const SiteData = ({ className, path }) => {
  const {
    pages,
    resources: { dashboard },
  } = useSiteData();

  const html = path
    .split(".")
    .reduce((d, k) => (d || {})[k], { ...pages, dashboard });

  return <RawHTML className={`site-data ${className || ""}`} html={html} />;
};

export default SiteData;
