import styled from "styled-components";

import vars from "variables";

import bnb from "assets/Common/FlipBox/bnb.svg";
import info from "assets/Common/FlipBox/info.svg";

import hodl from "assets/icon.png";

const Root = styled.div`
  & {
    height: 130px;
    perspective: 600px;
    position: relative;
  }

  &:last-child {
    margin-right: 0;
  }

  &.flippable > div {
    cursor: pointer;
  }

  & > div {
    width: 100%;
    height: 100%;
    position: relative;
    transition: transform 1s;
    transform-style: preserve-3d;
  }

  & > div.is-flipped {
    transform: rotateY(180deg);
  }

  & > div > div {
    width: calc(100% - 34px);
    height: calc(100% - 35px);
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    text-transform: uppercase;
    backface-visibility: hidden;
    position: absolute;
    background: ${vars.colors.marineBlue};
    border-radius: 6px;
  }

  &.flippable > div > div:first-child {
    &:after {
      content: "";
      top: 9px;
      right: 8px;
      width: 18px;
      height: 18px;
      display: block;
      position: absolute;
      background: url(${info});
      background-position: center;
      background-repeat: no-repeat;
      z-index: 9999999;
    }
  }

  & > div.is-flipped > div:first-child {
    &:after {
      display: none;
    }
  }

  & > div > div:nth-child(2) {
    transform: rotateY(180deg);
    background: ${vars.colors.marineBlue};
  }

  h4 {
    padding-bottom: 2px;
    color: ${vars.colors.blue};
    font-size: 0.7rem;
    letter-spacing: 1px;
  }

  h4.bnb,
  h4.hodl {
    padding-left: 24px;
    background-repeat: no-repeat;
    background-size: 16px;
  }

  h4.bnb {
    background-image: url(${bnb});
  }

  h4.hodl {
    background-image: url(${hodl});
  }

  h4 div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  h4 span.truncated {
    width: calc(50% - 20px);
    color: white;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p,
  .info {
    color: grey;
    font-size: 0.9rem;
    font-weight: normal;
    letter-spacing: 0.5px;
    line-height: 1.5;
    text-transform: none;
  }

  small {
    padding-top: 5px;
    color: ${vars.colors.grey};
    display: block;
    font-size: 1rem;
    font-weight: normal;
  }

  a {
    margin-top: 3px;
    color: ${vars.colors.blue};
    display: block;
    font-size: 0.7rem;
    letter-spacing: 1px;
    text-transform: lowercase;
  }

  .rank {
    padding-left: 97px;
  }

  .rank * {
    color: white !important;
  }

  .rank img {
    height: 75px;
    top: 25px;
    left: 25px;
    position: absolute;
  }

  .rank strong {
    font-size: 0.9rem;
    letter-spacing: 0.5px;
  }

  .rank small {
    padding-top: 0;
    font-size: 0.9rem;
    line-height: 0.7rem;
  }

  .wallet h4 {
    margin-top: -3px;
    font-size: 0.6rem;
  }

  .wallet strong {
    font-size: 1.3rem;
  }

  .wallet small {
    font-size: 0.95rem;
    line-height: 1.15;
  }

  .wallet label {
    color: white;
  }

  .wallet p {
    margin-top: -5px;
    padding-right: 0;
    font-size: 0.65rem;
  }

  ${vars.desktop.mediaQuery} {
    [data-row-count="2"] & {
      width: calc(50% - 11px);
    }

    [data-row-count="3"] & {
      width: calc(33% - 8px);
    }

    [data-row-count="4"] & {
      width: calc(25% - 14px);
    }

    & {
      margin-right: 17px;
      margin-bottom: 7px;
      display: inline-block;
    }

    & > div > div {
      width: calc(100% - 46px);
      padding: 15px 23px 20px 23px;
    }

    h4.bnb,
    h4.hodl {
      background-position: 0 6px;
    }

    strong {
      font-size: 1.65rem;
    }

    .rank {
      width: calc(100% - 120px);
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      margin-bottom: 22px;
    }

    & > div > div {
      padding: 22px 17px 20px 17px;
    }

    h4.bnb,
    h4.hodl {
      background-position: 0 1px;
    }

    strong {
      margin-bottom: 3px;
      line-height: 2.5rem;
      font-size: 1.55rem;
    }

    .info {
      padding-right: 0;
      font-size: 0.8rem;
    }

    .rank {
      width: calc(100% - 108px);
      padding-left: 91px;
    }

    .rank img {
      left: 16px;
    }

    .wallet {
      padding-top: 12px;
      padding-bottom: 28px;
    }

    .wallet strong {
      margin-bottom: -5px;
      display: block;
    }

    .wallet a {
      margin-top: 7px;
      font-size: 0.625rem;
    }
  }
`;

export { Root };
