import React, { useRef } from "react";
import { Root } from "./style";

import { navigate } from "gatsby";

const FlipBox = ({ cssClass, children }) => {
  const ref = useRef(null);
  const flippable = children[1] && children[1].props.children;

  const onClick = (event) => {
    if (event.target.parentElement.outerHTML.match("data-noflip")) {
      return;
    }
    if (event.target.tagName === "A") {
      if (!event.target.getAttribute("target")) {
        navigate(event.target.getAttribute("href"));
      }
    } else {
      ref.current.classList.toggle("is-flipped");
      setTimeout(
        () => ref.current && ref.current.classList.remove("is-flipped"),
        20000
      );
    }
  };

  return (
    <Root
      className={`flip-box ${cssClass ? cssClass : ""} ${
        flippable ? "flippable" : ""
      }`}
    >
      <div ref={ref} onClick={flippable ? onClick : () => {}}>
        {children}
        <i></i>
      </div>
    </Root>
  );
};

export default FlipBox;
